<template>
  <div>
    <b-nav tabs>
      <b-nav-item :active="aktuell" @click="aktuell = true">Aktuelle</b-nav-item>
      <b-nav-item :active="!aktuell" @click="aktuell = false">Vergangene</b-nav-item>
    </b-nav>
    <b-table 
      striped
      hover 
      bordered
      :fields="fields"
      :items="aktuell ? $store.state.bookings.current : $store.state.bookings.old"
      :sort-by="`fromdate`"
      :sort-desc="false"
      :tbody-tr-class="rowClass"
      >
      <template #cell(fullname)="row">
        <!-- `row.item` is the value after formatted by the Formatter -->
        <span @click="row.toggleDetails" :id="`${row.item.fromdate + row.item.todate + row.item.part}name`" style="text-decoration:underline;cursor:pointer;">{{ bookingByGuest(row.item) ? row.item.name + ' ' + row.item.lname : "Eigenbedarf" }}</span>
        <!-- <span :id="`${row.item.fromdate + row.item.todate + row.item.part}name`" :style="`${bookingByGuest(row.item) ? 'text-decoration:underline;cursor:pointer;' : ''}`">{{ bookingByGuest(row.item) ? row.item.name + ' ' + row.item.lname : "Eigenbedarf" }}</span>
        <b-tooltip v-if="bookingByGuest(row.item)" :target="`${row.item.fromdate + row.item.todate + row.item.part}name`" triggers="hover">
          Email: <a :href="`mailto:${row.item.email}`">{{row.item.email}}</a><br>
          Tel: {{row.item.tel}}
        </b-tooltip>
        <b-button v-if="bookingByGuest(row.item)" size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
        </b-button> -->
      </template>

      <template #row-details="row">
        <b-card>
          <b-row v-if="bookingByGuest(row.item)">
            <b-col cols="6">
              <b-row class="mb-2">
                <b-col cols="6" class="text-sm-right"><b>Name:</b></b-col>
                <b-col cols="6" class="text-sm-left">{{ row.item.name + ' ' + row.item.lname }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6" class="text-sm-right"><b>Alter:</b></b-col>
                <b-col cols="6" class="text-sm-left">{{ row.item.age }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6" class="text-sm-right"><b>Email:</b></b-col>
                <b-col cols="6" class="text-sm-left"><a :href="`mailto:${row.item.email}`">{{row.item.email}}</a></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6" class="text-sm-right"><b>Tel.:</b></b-col>
                <b-col cols="6" class="text-sm-left">{{ row.item.tel }}</b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row class="mb-2">
                <b-col cols="6" class="text-sm-right"><b>Personen:</b></b-col>
                <b-col cols="6" class="text-sm-left">{{ row.item.persons }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6" class="text-sm-right"><b>Preis:</b></b-col>
                <b-col cols="6" class="text-sm-left">{{ row.item.price.replace('.',',') + ' €' }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6" class="text-sm-right"><b>Anmerkungen:</b></b-col>
                <b-col cols="6" class="text-sm-left">{{ row.item.note }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6" class="text-sm-right"><b>Rechnung:</b></b-col>
                <b-col cols="6" class="text-sm-left"><a :href="`/showinvoice.php?invoicenr=${row.item.invoicenr}`" target="_blank">{{ row.item.invoicenr }}</a></b-col>
              </b-row>
            </b-col>
          </b-row>
          
          <b-row style="margin-top:1rem;">
            <b-col cols="6">
              <b-button size="sm" v-if="row.item.state == 0" @click="updateState(row, 1)" variant="warning">Buchung bestätigen</b-button>
              <b-button size="sm" @click="confirmDelete(row)" variant="danger">Buchung entfernen</b-button>
            </b-col>
            <b-col cols="6">
              <b-button size="sm" @click="row.toggleDetails">Details verbergen</b-button>
            </b-col>
          </b-row>
          
        </b-card>
      </template>

      <!-- <template #cell(persons)="data"> -->
        <!-- `data.value` is the value after formatted by the Formatter -->
        <!-- <span :id="`${data.value.fromdate + data.value.todate}persons`" style="text-decoration:underline;cursor:pointer;">  {{ Number(data.value.persons) }}  </span> -->
        <!-- <b-tooltip :target="`${data.value.fromdate + data.value.fromdate}persons`" triggers="hover">
          Erwachsene: {{data.value.adults}}<br>
          Kinder: {{data.value.children}}
        </b-tooltip> -->
      <!-- </template> -->
      <template #cell(comment)="row">
        <!-- `row.item` is the value after formatted by the Formatter -->
        <span :id="`${row.item.invoicenr}comment`" style="cursor:pointer;">{{ row.item.comment == null ? "" : shortenNote(row.item.comment) }}</span>
        <b-tooltip :target="`${row.item.fromdate + row.item.todate + row.item.part}note`" triggers="hover">
          {{row.item.note}}
        </b-tooltip>
      </template>
    </b-table>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      fields: [
        {
          key: 'fromdate',
          label: 'Von',
          sortable: true,
          formatter: (value) => {
            let temp = Date.parse(value)
            return new Date(temp).toLocaleDateString()
          }
        },
        {
          key: 'todate',
          label: 'Bis',
          sortable: true,
          formatter: (value) => {
            let temp = Date.parse(value)
            return new Date(temp).toLocaleDateString()
          }
        },
        // {
        //   key: 'name',
        //   label: 'Vorname'
        // },
        // {
        //   key: 'lname',
        //   label: 'Nachname'
        // },
        {
          key: 'fullname',
          label: 'Buchung',
          formatter: (value, key, item) => {
            return item
          }
        },
        // {
        //   key: 'age',
        //   label: 'Alter'
        // },
        // {
        //   key: 'persons',
        //   label: 'Personen',
        //   formatter: (value, key, item) => {
        //     return item
        //   }
        // },
        // {
        //   key: 'adults',
        //   label: 'Erwachsene'
        // },
        // {
        //   key: 'children',
        //   label: 'Kinder'
        // },
        {
          key: 'part',
          label: 'Hausteil',
          formatter: (value) => {
            switch(value){
              case "full": return "Haupthaus + Apt."
              case "upper": return "Haupthaus"
              case "lower": return "Appartement"
              default: return "Error"
            }
          }
        },
        {
          key: 'comment',
          label: 'Kommentar',
          formatter: (value, key, item) => {
            return item
          }
        },
        // {
        //   key: 'note',
        //   label: 'Anmerkungen',
        //   formatter: (value, key, item) => {
        //     return item
        //   }
        // },
        // {
        //   key: 'price',
        //   label: 'Preis',
        //   formatter: (value) => {
        //     return value + " €";
        //   }
        // },
      ],
      aktuell: true,
    }
  },
  methods: {
    shortenNote(note) {
      if (note.length <= 50){
        return note
      }
      else {
        return note.substring(0, 30) + "..."
      }
    },
    bookingByGuest(param) {
      if (!param.persons){
        return false
      }
      else {
        return true
      }
    },
    updateState(row, newState) {
      let form = {
        fromdate: row.item.fromdate,
        todate: row.item.todate,
        part: row.item.part,
        state: newState,
        action: "update"
      }

      axios
      .post('/updatedata.php', form)
      .then(response => { 
        if (response.data.includes("Success")){
          this.makeToast("Buchung bestätigt","Buchung erfolgreich bestätigt.", "success")
          this.$parent.queryDates();
          this.$parent.queryBookings();
        }
        else{
          this.makeToast("Bestätigung fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
        }
        })
      .catch(error => {
        console.log(error)
        this.makeToast("Bestätigung fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
      })

    },
    deleteEntry(row) {
      let form = {
        fromdate: row.item.fromdate,
        todate: row.item.todate,
        part: row.item.part,
        action: "delete",
        table: (this.bookingByGuest(row.item) ? "buchung" : "eigen")
      }

      axios
      .post('/updatedata.php', form)
      .then(response => { 
        if (response.data.includes("Success")){
          this.makeToast("Buchung entfernt","Buchung erfolgreich entfernt.", "success")
          this.$parent.queryDates();
          this.$parent.queryBookings();
        }
        else{
          this.makeToast("Entfernen fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
        }
        })
      .catch(error => {
        console.log(error)
        this.makeToast("Entfernen fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
      })
    },
    confirmDelete(row) {
      this.boxTwo = ''
      this.$bvModal.msgBoxConfirm('Eintrag entfernen?', {
        title: 'Entfernen bestätigen',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Entfernen',
        cancelTitle: 'Abbrechen',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value){
            this.deleteEntry(row)
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    makeToast(toastTitle, message, variant = null) {
      this.$bvToast.toast(message, {
        title: toastTitle,
        variant: variant,
        autoHideDelay: 10000,
        appendToast: false,
        solid: true
      })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (this.bookingByGuest(item)) {
        if (item.state == 0) {
          let theDate = new Date(item.bookingts)
          if (new Date() >= theDate.setDate(theDate.getDate() + 7)) return 'table-danger'
          else return 'table-warning'
        }
        else if (item.state > 1) return 'table-success'
      }
      else if (!this.bookingByGuest(item)) return 'table-info'
    }
  }
}
</script>

<style lang="scss" scoped>

.btn {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

</style>