<template>
  <div>
    <b-container>
      <navbar />
      <div class="spacer"/>
      <b-card>
        <h3>Kalender</h3>
        <PickDate />
      </b-card>
      <div class="spacer"/>
      <b-card>
        <h3>Buchungen</h3>
        <bookingtable />
      </b-card>
      <div class="spacer"/>
      <b-card>
        <h3>Preise</h3>
        <prices />
      </b-card>
      <div class="spacer"/>
      <b-card>
        <h3>Rechnung</h3>
        <invoice />
      </b-card>
      <div class="spacer"/>
      <b-card>
        <h3>Hausregeln</h3>
        <houserules />
      </b-card>
      <div class="spacer"/>
    </b-container>
  </div>
</template>

<script>
import PickDate from '@/components/PickDate.vue'
import bookingtable from '@/components/table.vue'
import prices from '@/components/prices.vue'
import invoice from '@/components/invoice.vue'
import houserules from '@/components/houserules.vue'
import navbar from '@/components/menu.vue'
import axios from 'axios'
export default {
  components: {
    PickDate,
    bookingtable,
    prices,
    invoice,
    houserules,
    navbar
  },
  mounted(){
    this.cyclicCheck();
  },
  methods: {
    cyclicCheck: async function(){
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      const bool = true
      while(bool){
        this.queryDates();
        this.queryBookings();
        await sleep(60000);
      }
    },
    addDays: Date.prototype.addDays = function(days) {
      this.setUTCDate(this.getUTCDate() + parseInt(days));
      return this
    },
    queryDates: function(){
      axios
      .post('/request.php')
      .then(response => { 
        let dates = response.data;
        let full = [];
        let upper = [];
        let lower = [];

        // let getDatesBetweenDates = (startDate, endDate) => {
        //   let dates = []
        //   //to avoid modifying the original date
        //   const theDate = new Date(startDate)
        //   while (theDate < endDate) {
        //     dates.push(new Date(theDate))
        //     theDate.setDate(theDate.getDate() + 1)
        //   }
        //   //console.log(dates)
        //   return dates
        // }

        let toUTCDay = (day) => {
          let date = new Date();
          date.setUTCFullYear(day.getUTCFullYear())
          date.setUTCMonth(day.getUTCMonth())
          date.setUTCDate(day.getUTCDate())
          date.setUTCHours(0)
          date.setUTCMinutes(0)
          date.setUTCSeconds(0)
          date.setUTCMilliseconds(0)
          return date
        }

        let todayUTC = toUTCDay(new Date());
        //console.log(todayUTC);

        //console.log(dates)

        for (let part in dates){
          //console.log(part)
          let temp = [];
          for (let x of dates[part]){
            //console.log(x)
            if (x != null){
              let from = new Date(x.start);
              let to = new Date(x.end);
              if (to > todayUTC){
                // if (from <= todayUTC){
                //   from = new Date((todayUTC).addDays(1));
                // }
                temp.push({start: new Date(from), end: new Date(to)});
                // temp = [...temp, ...getDatesBetweenDates(from, to)];
                //console.log(temp)
              }
            }
          }
          //console.log(temp)
          switch(part){
            case "full":
              full = temp;
              break;
            case "upper":
              upper = temp;
              break;
            case "lower":
              lower = temp;
              break;
          }
        }

        // let toBeMoved = [];
        // for (let x of upper){
        //   for (let y of lower){
        //     if (x.toISOString() === y.toISOString()){
        //       toBeMoved.push(new Date(x))
        //     }
        //   }
        // }

        //console.log(toBeMoved)

        // full = [...full, ...toBeMoved]
        // full.sort((a, b) => a - b)
        // full = [...this.$store.state.calendar.disabledDates, ...full]

        // let newUpper = []
        // let newLower = []

        // for (let x of lower){
        //   let bvar = false
        //   for (let y of toBeMoved){
        //     if ((x.toISOString() === y.toISOString())){
        //       bvar = true
        //     }
        //   }
        //   if (!bvar) {
        //     newLower.push(new Date(x))
        //   }
        // }

        // for (let x of upper){
        //   let bvar = false
        //   for (let y of toBeMoved){
        //     if ((x.toISOString() === y.toISOString())){
        //       bvar = true
        //     }
        //   }
        //   if (!bvar) {
        //     newUpper.push(new Date(x))
        //   }
        // }

        // let booked = {
        //   full: full,
        //   upper: newUpper,
        //   lower: newLower
        // }

        let booked = {
          full: full,
          upper: upper,
          lower: lower
        }
        
        this.$store.commit('setBooked', booked)
         
      })
      .catch(error => {
        console.log(error)
      })
    },
    queryBookings: function(){
      axios
      .post('/bookingdata.php')
      .then(response => { 
        let dates = response.data;
        let old = dates.old;
        let current = dates.current;

        let bookings = {
          old: old,
          current: current
        }
        
        this.$store.commit('setBookings', bookings)
         
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #435165;
}
.card-body {
  background-color: white;
}
h3 {
  $space: 1.5rem;
  margin-top: $space;
  margin-bottom: $space;
}
</style>