<template>
  <div id="app">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.1/css/all.css">
    <login v-if="!loggedIn" v-on:login_success="logIn"/>
    <home v-if="loggedIn"/>
    <!--<img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import login from './components/login.vue'
import home from './components/home.vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    //HelloWorld,
    login,
    home
  },
  data() {
    return {
      loggedIn: false
    }
  },
  methods: {
    logIn: function() {
			this.loggedIn = true
		},
  },
  mounted(){
    axios
      .post('/check.php')
      .then(response => {
        if (response.data.includes("Success")){
          this.logIn()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>

<style lang="scss">
* {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "segoe ui", roboto, oxygen, ubuntu, cantarell, "fira sans", "droid sans", "helvetica neue", Arial, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    background-color: #435165;
    text-align: center;
}
.spacer {
  $space: 2rem;
  margin-top: $space;
  margin-bottom: $space;
}
</style>
