<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info" class="radius">
      <b-navbar-brand href="#">Admin-Bereich</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="#" @click="logout">Logout</b-nav-item>
        <!-- <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
      </b-navbar-nav>

      </b-collapse>
    </b-navbar> 
  </div>
</template>

<script>
import axios from 'axios'
export default {
  methods: {
    logout: function(){
      axios
      .get('/logout.php')
      .then(response => { 
        this.$parent.$parent.loggedIn = false
      })
      .catch(error => {
        console.log(error)
        this.$parent.$parent.loggedIn = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.radius {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}
</style>