<template>
  <div>
    <b-row>
      <b-col>
        <b-card v-for="(item, index) in houserules.de" :key="index" class="mb-2">
          <h4>{{index+1}}. Hausregel</h4>
          <b-input-group prepend="Deutsch">
            <b-form-input v-model="houserules.de[index]" :disabled="!modify"></b-form-input>
          </b-input-group>
          <b-input-group prepend="Englisch">
            <b-form-input v-model="houserules.en[index]" :disabled="!modify"></b-form-input>
          </b-input-group>
          <b-input-group prepend="Niederländisch">
            <b-form-input v-model="houserules.nl[index]" :disabled="!modify"></b-form-input>
          </b-input-group>
          <b-input-group prepend="Französisch">
            <b-form-input v-model="houserules.fr[index]" :disabled="!modify"></b-form-input>
          </b-input-group>
          <b-button @click="confirmDelete(index)" variant="danger" :disabled="disableButton">Entfernen</b-button>
        </b-card>
      </b-col>
      <div class="spacer"/>
      <div class="w-100"></div>
      <b-col cols="6">
        <b-button @click="addRule" variant="success" :disabled="disableButton">Hinzufügen</b-button>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox v-model="modify" size="lg" switch>Bearbeiten</b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-button @click="sendHouserules" :disabled="disableButton">Speichern</b-button>
      </b-col>
      
    </b-row>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      houserules: {
        de: [],
        en: [],
        nl: [],
        fr: []
      },
      modify: false,
    }
  },
  mounted(){
    this.queryHouserules()
  },
  methods: {
    queryHouserules: function(){
      axios
      .post('/houserules.php')
      .then(response => { 
        let houserulesdata = response.data;
        
        this.$store.commit('setHouserules', JSON.parse(JSON.stringify(houserulesdata)))
        this.houserules = JSON.parse(JSON.stringify(houserulesdata))
         
      })
      .catch(error => {
        console.log(error)
      })
    },
    sendHouserules: function(){
      axios
      .post('/houserules.php', this.houserules)
      .then(response => { 
        if (response.data.includes("Success")){
          this.queryHouserules();
          this.modify = false;
          this.makeToast("Daten gespeichert","Rechnungsdaten erfolgreich gespeichert", "success")
        }
        else{
          this.makeToast("Speichern fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
        }
        })
      .catch(error => {
        console.log(error)
        this.makeToast("Speichern fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
      })
    },
    makeToast(toastTitle, message, variant = null) {
      this.$bvToast.toast(message, {
        title: toastTitle,
        variant: variant,
        autoHideDelay: 10000,
        appendToast: false,
        solid: true
      })
    },
    addRule() {
      this.houserules.de.push("");
      this.houserules.en.push("");
      this.houserules.nl.push("");
      this.houserules.fr.push("");
    },
    removeRule(index){
      this.houserules.de.splice(index, 1);
      this.houserules.en.splice(index, 1);
      this.houserules.nl.splice(index, 1);
      this.houserules.fr.splice(index, 1);
    },
    confirmDelete(index) {
      this.boxTwo = ''
      this.$bvModal.msgBoxConfirm((index+1) + '. Hausregel entfernen?', {
        title: 'Entfernen bestätigen',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Entfernen',
        cancelTitle: 'Abbrechen',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value){
            this.removeRule(index)
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
  },
  computed: {
    disableButton() {
      if (!this.modify){
        return true
      }
      return false
    },
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  margin-top: 5px;
  margin-bottom: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>