//index.js
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: {},
    calendar: {
      pickedRange: {
        start: new Date(),
        end: new Date()
      },
      minRange: 1,
      disabledDates: [
        {
          start: null,
          end: new Date()
        }
      ],
      booked: {
        full: [],
        upper: [],
        lower: []
      },
    },
    bookings: {
      current: [],
      old: []
    },
    booking: {
      part: 'full',
    },
    price: {
      basis: {
        upper: 0,
        full: 0
      },
      saison: {
        winter: {
          kw: 0,
          prozent: 0
        },
        frueh: {
          kw: 0,
          prozent: 0
        },
        sommer: {
          kw: 0,
          prozent: 0
        },
        herbst: {
          kw: 0,
          prozent: 0
        }
      },
      aufschlag: 0,
      service: 0
    },
    invoice: {
      account: {
        iban: "",
        bic: "",
        name: ""
      },
      contact: {
        number: ""
      },
      kaution: 0
    },
    houserules: {
      de: [],
      en: [],
      nl: [],
      fr: []
    },
  },

  getters: {
    errors(state) {
      return state.errors;
    }
  },

  mutations: {
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    setRange(state, range) {
      let toUTCDay = (day) => {
        day.setMinutes(day.getMinutes() - day.getTimezoneOffset())
        let date = new Date();
        date.setUTCFullYear(day.getUTCFullYear())
        date.setUTCMonth(day.getUTCMonth())
        date.setUTCDate(day.getUTCDate())
        date.setUTCHours(0)
        date.setUTCMinutes(0)
        date.setUTCSeconds(0)
        date.setUTCMilliseconds(0)
        return date
      }

      state.calendar.pickedRange.start = toUTCDay(range.start)
      state.calendar.pickedRange.end = toUTCDay(range.end)
    },
    setBooked(state, newBooked) {
      state.calendar.booked = newBooked
    },
    setSelectedRoom(state, room){
      state.booking.part = room
    },
    setBookings(state, book){
      state.bookings = book
    },
    setPrice(state, newprices){
      state.price = newprices
    },
    setInvoicedata(state, newinvoicedata){
      state.invoice = newinvoicedata
    },
    setHouserules(state, newhouserules){
      state.houserules = newhouserules
    },
  },

  actions: {
    setErrors({ commit }, errors) {
      commit("SET_ERRORS", errors);
    }
  }
});
