<template>
  <div class="login">
      <b-alert variant="warning" v-if="info!=''" show>{{ info }}</b-alert>
			<h1>Login</h1>
			<form v-on:submit.prevent="sendForm">
				<label for="username">
					<i class="fas fa-user"></i>
				</label>
				<input type="text" name="username" placeholder="Username" id="username" v-model="username" required autofocus>
				<label for="password">
					<i class="fas fa-lock"></i>
				</label>
				<input type="password" name="password" placeholder="Password" id="password" v-model="password" required>
				<input type="submit" value="Login">
			</form>
		</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      username: "",
      password: "",
      info: ""
    }
  },
  methods: {
    sendForm() {
      let form = {
        username: this.username,
        passwd: this.password
      }
      
      axios
      .post('/authenticate.php', form)
      .then(response => {
        if (response.data.includes("Success")){
          this.info = "Success"
          this.$emit('login_success', 1)
        }
        else {
          this.info = response.data
        }
      })
      .catch(error => {
        console.log(error)
        this.info = "Connection failed"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
    width: 400px;
    background-color: #ffffff;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
    margin: 100px auto;
}
.login h1 {
    text-align: center;
    color: #5b6574;
    font-size: 24px;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid #dee0e4;
}
.login form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
}
.login form label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #3274d6;
    color: #ffffff;
}
.login form input[type="password"], .login form input[type="text"] {
    width: 310px;
    height: 50px;
    border: 1px solid #dee0e4;
    margin-bottom: 20px;
    padding: 0 15px;
}
.login form input[type="submit"] {
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    background-color: #3274d6;
    border: 0;
    cursor: pointer;
    font-weight: bold;
    color: #ffffff;
transition: background-color 0.2s;
}
.login form input[type="submit"]:hover {
	background-color: #2868c7;
    transition: background-color 0.2s;
}
</style>