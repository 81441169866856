<template>
  <div>
    <b-row>
      <b-col cols="4">
        <h4>Basispreis</h4>
        <b-input-group prepend="Haupthaus" append="€">
          <b-form-input type="number" min=0 step="0.01" v-model="price.basis.upper" :disabled="!modify" no-wheel></b-form-input>
        </b-input-group>
        <b-input-group prepend="Ganzes Haus" append="€">
          <b-form-input type="number" min=0 step="0.01" v-model="price.basis.full" :disabled="!modify" no-wheel></b-form-input>
        </b-input-group>
        <h5>Aufschlag/Tag &lt;7 Nächte</h5>
        <b-input-group prepend="Aufschlag" append="%">
          <b-form-spinbutton type="number" min=0 v-model="price.aufschlag" :disabled="!modify"></b-form-spinbutton>
        </b-input-group>
        <h4>Servicepaket</h4>
        <b-input-group prepend="pro Person" append="€">
          <b-form-input type="number" min=0 step="0.01" v-model="price.service" :disabled="!modify" no-wheel></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <h4>Saison Preis</h4>
        <b-input-group prepend="Frühjahr" append="%">
          <b-form-spinbutton type="number" min=0 max="100" step="1" v-model="price.saison.frueh.prozent" :disabled="!modify"></b-form-spinbutton>
        </b-input-group>
        <b-input-group prepend="Sommer" append="%">
          <b-form-spinbutton type="number" min=0 max="100" step="1" v-model="price.saison.sommer.prozent" :disabled="!modify"></b-form-spinbutton>
        </b-input-group>
        <b-input-group prepend="Herbst" append="%">
          <b-form-spinbutton type="number" min=0 max="100" step="1" v-model="price.saison.herbst.prozent" :disabled="!modify"></b-form-spinbutton>
        </b-input-group>
        <b-input-group prepend="Winter" append="%">
          <b-form-spinbutton type="number" min=0 max="100" step="1" v-model="price.saison.winter.prozent" :disabled="!modify"></b-form-spinbutton>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <h4>Saison Anfang</h4>
        <b-input-group prepend="Frühjahr" append="KW">
          <b-form-spinbutton type="number" min=0 step="1" v-model="price.saison.frueh.kw" :disabled="!modify"></b-form-spinbutton>
        </b-input-group>
        <b-input-group prepend="Sommer" append="KW">
          <b-form-spinbutton type="number" min=0 step="1" v-model="price.saison.sommer.kw" :disabled="!modify"></b-form-spinbutton>
        </b-input-group>
        <b-input-group prepend="Herbst" append="KW">
          <b-form-spinbutton type="number" min=0 step="1" v-model="price.saison.herbst.kw" :disabled="!modify"></b-form-spinbutton>
        </b-input-group>
        <b-input-group prepend="Winter" append="KW">
          <b-form-spinbutton type="number" min=0 step="1" v-model="price.saison.winter.kw" :disabled="!modify"></b-form-spinbutton>
        </b-input-group>
      </b-col>
      <div class="spacer"/>
    <div class="w-100"></div>
    <b-col cols="6">
      </b-col>
      <b-col cols="3">
        <b-form-checkbox v-model="modify" size="lg" switch>Bearbeiten</b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-button @click="sendPrices" :disabled="disableButton">Speichern</b-button>
      </b-col>
      
    </b-row>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      price: {
        basis: {
          upper: 0,
          full: 0
        },
        saison: {
          winter: {
            kw: 0,
            prozent: 0
          },
          frueh: {
            kw: 0,
            prozent: 0
          },
          sommer: {
            kw: 0,
            prozent: 0
          },
          herbst: {
            kw: 0,
            prozent: 0
          }
        },
        aufschlag: 0,
        service: 0
      },
      modify: false,
    }
  },
  mounted(){
    this.queryPrice()
  },
  methods: {
    queryPrice: function(){
      axios
      .post('/getprice.php')
      .then(response => { 
        let prices = response.data;
        
        this.$store.commit('setPrice', JSON.parse(JSON.stringify(prices)))
        this.price = JSON.parse(JSON.stringify(prices))
         
      })
      .catch(error => {
        console.log(error)
      })
    },
    sendPrices: function(){
      axios
      .post('/getprice.php', this.price)
      .then(response => { 
        if (response.data.includes("Success")){
          this.queryPrice();
          this.modify = false;
          this.makeToast("Daten gespeichert","Preise erfolgreich gespeichert", "success")
        }
        else{
          this.makeToast("Speichern fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
        }
        })
      .catch(error => {
        console.log(error)
        this.makeToast("Speichern fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
      })
    },
    makeToast(toastTitle, message, variant = null) {
      this.$bvToast.toast(message, {
        title: toastTitle,
        variant: variant,
        autoHideDelay: 10000,
        appendToast: false,
        solid: true
      })
    },
  },
  computed: {
    disableButton() {
      if (!this.modify){
        return true
      }
      return false
    },
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  margin-top: 5px;
  margin-bottom: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>