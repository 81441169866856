import Vue from 'vue'
import './plugins/vuex'
import store from '@/store';
import './plugins/bootstrap-vue'
import './plugins/v-calendar'
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
