<template>
  <div>
    <b-row>
      <b-col cols="4">
        <h4>Kontodaten</h4>
        <b-input-group prepend="Empfänger">
          <b-form-input v-model="invoice.account.name" :disabled="!modify"></b-form-input>
        </b-input-group>
        <b-input-group prepend="IBAN">
          <b-form-input v-model="invoice.account.iban" :disabled="!modify"></b-form-input>
        </b-input-group>
        <b-input-group prepend="BIC">
          <b-form-input v-model="invoice.account.bic" :disabled="!modify"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <h4>Kontakt</h4>
        <b-input-group prepend="Tel.">
          <b-form-input v-model="invoice.contact.number" :disabled="!modify"></b-form-input>
        </b-input-group>
        <h4>Schlüsselbox-Code</h4>
        <b-input-group prepend="Code">
          <b-form-input v-model="invoice.code" :disabled="!modify"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <h4>Kaution</h4>
        <b-input-group prepend="Kaution" append="€">
          <b-form-input type="number" min=0 step="0.01" v-model="invoice.kaution" :disabled="!modify" no-wheel></b-form-input>
        </b-input-group>
      </b-col>
      <div class="spacer"/>
    <div class="w-100"></div>
    <b-col cols="6">
      </b-col>
      <b-col cols="3">
        <b-form-checkbox v-model="modify" size="lg" switch>Bearbeiten</b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-button @click="sendInvoicedata" :disabled="disableButton">Speichern</b-button>
      </b-col>
      
    </b-row>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      invoice: {
        account: {
          iban: "",
          bic: "",
          name: ""
      },
      contact: {
        number: ""
      },
      kaution: 0,
      code: ""
    },
      modify: false,
    }
  },
  mounted(){
    this.queryInvoicedata()
  },
  methods: {
    queryInvoicedata: function(){
      axios
      .post('/invoicedata.php')
      .then(response => { 
        let invoicedata = response.data;
        
        this.$store.commit('setInvoicedata', JSON.parse(JSON.stringify(invoicedata)))
        this.invoice = JSON.parse(JSON.stringify(invoicedata))
         
      })
      .catch(error => {
        console.log(error)
      })
    },
    sendInvoicedata: function(){
      axios
      .post('/invoicedata.php', this.invoice)
      .then(response => { 
        if (response.data.includes("Success")){
          this.queryInvoicedata();
          this.modify = false;
          this.makeToast("Daten gespeichert","Rechnungsdaten erfolgreich gespeichert", "success")
        }
        else{
          this.makeToast("Speichern fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
        }
        })
      .catch(error => {
        console.log(error)
        this.makeToast("Speichern fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
      })
    },
    makeToast(toastTitle, message, variant = null) {
      this.$bvToast.toast(message, {
        title: toastTitle,
        variant: variant,
        autoHideDelay: 10000,
        appendToast: false,
        solid: true
      })
    },
  },
  computed: {
    disableButton() {
      if (!this.modify){
        return true
      }
      return false
    },
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  margin-top: 5px;
  margin-bottom: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>