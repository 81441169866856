 
<template>
  <div class="datepicker">
    <b-container>
      <b-row class="justify-content-md-center">
        <div class="w-100"></div>
        <b-col>
          <v-date-picker 
          :attributes="attributes"
          mode="range"
          :disabled-dates="$store.state.calendar.disabledDates"
          :timezone="timezone"
          :columns="$screens({ default: 1, md:2, lg: 3, xl: 4 })"
          :step="1"
          is-range
          is-inline 
          :value="$store.state.calendar.pickedRange"
          @input="updatePickedRange($event)" />
        </b-col>
        <div class="w-100"></div>
        <h3>Zeitraum Reservieren</h3>
        <div class="w-100"></div>
        <b-col cols="8">
          <b-form-textarea
            id="note"
            v-model="form.note"
            placeholder="Kommentar..."
            trim
            rows="2"
          ></b-form-textarea>
        </b-col>
        <b-col cols="4">
          <b-form-select v-model="$store.state.booking.part" :options="optionsRoom"></b-form-select>
          <br><br>
          <b-button :disabled="disableButton" @click="startBooking" variant="success">Reservieren</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'PickDate',
  data() {
    return {
      style: {
        today: {
          key: 'today',
          highlight: {
            color: 'green',
            backgroundColor: 'green',
            fillMode: 'light',
          },
          order: 3,
          dates: new Date(),
          popover: {
            label: "Heute"
          }
        },
        upper: {
          key: 'upper',
          highlight: {
            start: { fillMode: 'solid', color: 'green' },
            base: { fillMode: 'light', color: 'green' },
            end: { fillMode: 'solid', color: 'green' },
          },
          //bar: 'purple',
          popover: {
            label: "Haupthaus gebucht"
          }
        },
        lower: {
          key: 'lower',
          highlight: {
            start: { fillMode: 'solid', color: 'orange' },
            base: { fillMode: 'light', color: 'orange' },
            end: { fillMode: 'solid', color: 'orange' },
          },
          popover: {
            label: "Apartment gebucht"
          }
        },
        full: {
          key: 'full',
          highlight: {
            start: { fillMode: 'solid', color: 'purple' },
            base: { fillMode: 'light', color: 'purple' },
            end: { fillMode: 'solid', color: 'purple' },
          },
          //bar: 'purple',
          popover: {
            label: "Haupthaus + Apt. gebucht"
          }
        },
      },
      selectedRoom: 'full',
      pickedDateRange: this.pickedDays(),
      form: {
        note: ""
      }
    }
  },
  methods: {
    addDays: Date.prototype.addDays = function(days) {
      this.setDate(this.getDate() + parseInt(days));
      return this
    },
    updatePickedRange: function(event){
      this.$store.commit('setRange', event)

      this.pickedDateRange = this.pickedDays()
    },
    pickedDays: function(){
      const getDaysBetweenDates = (startDate, endDate) => {
        let days = 0
        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate < endDate) {
          days += 1
          theDate.setDate(theDate.getDate() + 1)
        }
        return days
      }

      return getDaysBetweenDates(this.$store.state.calendar.pickedRange.start, this.$store.state.calendar.pickedRange.end)
    },
    startBooking: function(){
      let form = {
        from: this.$store.state.calendar.pickedRange.start,
        to: this.$store.state.calendar.pickedRange.end,
        part: this.$store.state.booking.part,
        note: this.form.note
      }

      axios
      .post('/bookingdata.php', form)
      .then(response => { 
        if (response.data.includes("Success")){
          this.makeToast("Reservierung erfolgreich","Zeitraum erfolgreich gespeichert.", "success")
          this.$parent.queryDates();
          this.$parent.queryBookings();
          this.form.note = "";
        }
        else{
          this.makeToast("Reservierung fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
        }
        })
      .catch(error => {
        console.log(error)
        this.makeToast("Reservierung fehlgeschlagen","Ein Fehler ist aufgerteten.", "danger")
      })
    },
    makeToast(toastTitle, message, variant = null) {
      this.$bvToast.toast(message, {
        title: toastTitle,
        variant: variant,
        autoHideDelay: 10000,
        appendToast: false,
        solid: true
      })
    },
  },
  computed: {
    attributes() {
      return [this.style.today, {...this.style.upper, dates: this.$store.state.calendar.booked.upper}, {...this.style.lower, dates: this.$store.state.calendar.booked.lower}, {...this.style.full, dates: this.$store.state.calendar.booked.full}]
    },
    timezone(){
      return 'UTC'
    },
    littleRange() {
      if (this.pickedDateRange < this.$store.state.calendar.minRange){
        return true
      }
      return false
    },
    disableButton() {
      if (this.littleRange){
        return true
      }
      return false
    },
    optionsRoom() {
      const getDatesBetweenDates = (startDate, endDate) => {
        let dates = []
        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate <= endDate) {
          dates = [...dates, new Date(theDate)]
          theDate.setDate(theDate.getDate() + 1)
        }
        return dates
      }

      let pickedDates = getDatesBetweenDates(this.$store.state.calendar.pickedRange.start, this.$store.state.calendar.pickedRange.end)

      let full = false, upper = false, lower = false

        // for (let x of pickedDates){
        //   for (let y of this.$store.state.calendar.booked.upper){
        //     if (x.toISOString() === y.toISOString()){
        //       full = true
        //       upper = true
        //     }
        //   }
        //   for (let y of this.$store.state.calendar.booked.lower){
        //     if (x.toISOString() === y.toISOString()){
        //       full = true
        //       lower = true
        //     }
        //   }
        // }

        // let mixed = null

        // if (!full){
        //   this.$store.commit('setSelectedRoom', 'full')
        // }
        // else if (!upper){
        //   this.$store.commit('setSelectedRoom', 'upper')
        // }
        // else if (!lower){
        //   this.$store.commit('setSelectedRoom', 'lower')
        // }
        // else {
        //   this.$store.commit('setSelectedRoom', 'mixed')
        //   mixed = {value: 'mixed', text: 'Haupthaus/Appartement'}
        // }

        let options = [
          { value: 'full', text: 'Haupthaus mit Apartment', disabled: full },
          { value: 'lower', text: 'Apartment', disabled: lower }
        ]

        // if (mixed != null) {
        //   options = [...options, mixed]
        // }

      return options
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#top {
  margin-top: 2rem;
}
h3 {
  $space: 2rem;
  margin-top: $space;
  margin-bottom: $space;
}

</style>
